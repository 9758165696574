import React, { useState } from 'react';
import gql from 'graphql-tag';
import Confirm from './Confirm'
import { Button } from '@mui/material';

const DELETE_TRAVEL = gql`
mutation DeleteTravel($id: String!) {
  deleteTravel(id:$id)
}
`;


const TravelDeleteButton = props => {
    let [confirmOpen, setConfirmOpen] = useState(false);
    
    return (
        <Confirm 
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={() => {
                deleteTravel(props.client, props.id, props.onDelete);
                setConfirmOpen(false)
            }}
            trigger={<Button 
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmOpen(true);
                }}
            >Delete</Button>} 
            />
    )
}

const deleteTravel = (client, id, onDelete) => {
    console.log('Delete travel: ' + id);
    
    client.mutate({
      mutation: DELETE_TRAVEL,
      variables: {id},
      refetchQueries: ['TravelInformation']
    }).then(data => {
      if (!data.data.deleteTravel) {
        console.log('Delete travel failed');
      }
      console.log('Deleted travel')
      onDelete?.(id)
    });
}

export default TravelDeleteButton;