import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validate from './Validate';
import { Button, Container, TextField } from '@mui/material';

const SIGN_IN_TOKEN = gql`
mutation SignIn($email:String!, $token:String!) {
  signInWithRecoveryToken(email:$email, token:$token)
}`
;

const SignInToken = props => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  let navigate = useNavigate();

  document.title = `navscout - Sign In`;

  const submit = () => {
    signIn(props.client, {email, token}, navigate)
  }

  return (<Container>
      <h3>Enter your email and token that was sent to you</h3>
      <form onSubmit={submit}>
        <TextField name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        <TextField type='password' name='token' placeholder='Token' value={token} onChange={e => setToken(e.target.value)} />
        <br/ ><br />
        <Container textAlign='center'>
          <Button
              disabled={!Validate.notBlank(email, token)}
              onClick={submit}
              >Sign In</Button>
          <br />
          <a href='/sign-in'>Sign In</a>
          &nbsp;&bull;&nbsp;
          <a href='/sign-in/get-token'>Register</a>
        </Container>
      </form>
    </Container>);
}

const signIn = (client, values, navigate) => {
  client.mutate({
    mutation: SIGN_IN_TOKEN,
    variables: values,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.signInWithRecoveryToken === null) {
      console.log('Sign in failed', values);
    } else {
      navigate('/');
    }
  });
}

export default SignInToken;