import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';

const AdContent = props => {
  const adUri = `${process.env.REACT_APP_REST_URI}/ad`;
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading) return;
    setLoading(true)

  fetch(
    adUri,
    {
        method: "GET",
        credentials: "include"
    }
    ).then(response => response.text())
    .then(data => setContent(data))
  
  })

    return (<Alert variant='outlined' icon={false} severity='info' style={{margin: '1em'}}>
      <AlertTitle>Ad</AlertTitle>
      <div dangerouslySetInnerHTML={{__html:content}} />
      </Alert>)
}

export default AdContent;