import React from 'react';
import Util from './Util';
import { Article, Chat, Cloud, DirectionsCar, Image, Map, Pageview, Share, Upload } from '@mui/icons-material';
import { Box, Link, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BASE_URL = 'https://www.navscout.com'


const SubjectContext = props => {
    const s = props.subject;
    const exclusions = props.exclude
    const navigate = useNavigate()

    const menuItems = []

    const ContextMenuItem = props => {
      return (<SpeedDialAction key={props.content} onClick={() => navigate(props.link)} icon={props.icon} />);
    }

    const addMenuItem = a => {
      const label = a?.props?.content
      const exclude = exclusions !== null && exclusions !== undefined && exclusions.indexOf(label) !== -1
      if (!exclude) {
        menuItems.push(a)
      }
    }
    
    addMenuItem(
      <SpeedDialAction icon={<Pageview />} link={subjectLink(s)} content='View' />    
    )

    if (s?.id && s?.type) {
      addMenuItem(
        <ContextMenuItem icon={<Chat />} link={`/chat?${s?.type?.toLowerCase()}=${s?.id}`} content='Discuss' />    
      )
    }

    if (s?.type === 'PLACE') {
        addMenuItem(
            <ContextMenuItem icon={<DirectionsCar />} link={'/drive/new?destination='+s.label} content='Drive' />    
        )
        addMenuItem(
            <ContextMenuItem icon={<Cloud />} link={subjectLink(s) + '/weather'} content='Weather' />    
        )
        addMenuItem(
          <ContextMenuItem icon={<Map />} link={'/place?subjectId=' + s.id} content='Map' />    
      )
        addMenuItem(
          <ContextMenuItem icon={<Article />} link={`/report?subjectId=${s.id}`} content='Reports' />    
        )
        addMenuItem(
          <ContextMenuItem icon={<Image />} link={`/gallery?subjectId=${s.id}`} content='Gallery' />    
        )
        if (props.account?.roles?.includes("REPORT")) {
          addMenuItem(
            <ContextMenuItem icon={<Upload />} link={`/gallery/upload?subjectId=${s.id}`} content='Upload Media' />
          )
        }
    }

    if (s?.type === 'PLACE' || s?.type === 'RECORD') {
      addMenuItem(
        <ContextMenuItem icon={<Share />} link={`https://twitter.com/intent/tweet?text=${encodeURIComponent(s.label)}&url=${encodeURIComponent(BASE_URL + subjectLink(s))}`} content='Share' />
      )
    }

    if (s?.type === 'RECORD' && props.account?.roles?.includes("POWER")) {
      addMenuItem(
        <ContextMenuItem icon={<Article />} link={`${process.env.REACT_APP_REST_URI}/record/${s.id}`} content='Data' />
      )
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Link href={subjectLink(s)}>{s.label}</Link>
        <SpeedDial 
          direction="right" 
          icon={<SpeedDialIcon sx={{ fontSize: 16 }} />} 
          open={props.open} 
          ariaLabel='Menu'
          sx={{
            '& .MuiSpeedDial-fab': {
              width: 36,    // Default is 56
              height: 36,   // Default is 56
              minHeight: 36 // Override min-height
            }
          }}      
          >
            {menuItems}
        </SpeedDial>
    </Box>
  )
}

const subjectLink = s => {
    if (s === null || s.type === null)  {
      return null
    }
    return Util.subjectLink(s.type, s.id)
  }

export default SubjectContext;