import React from 'react';

import _ from 'lodash'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


function exampleReducer(state, action) {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }
  
        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        }
      default:
        throw new Error()
    }
  }

const DataTable = props => {
    const tableData = []
    const labels = props.data?.labels
    const colCount = props.data?.data?.length || 0
    const rowCount = props.data?.data?.[0]?.length || 0

    const sData = props.data.data

    if (sData != null) {
        for (var row = 0; row < rowCount; row ++) {
            var rowData = {}
            for (var col = 0; col < colCount; col ++) {
                rowData[props.data.labels[col]] = sData[col][row]
            }
            tableData.push(rowData)
        } 
    }

    const [state, dispatch] = React.useReducer(exampleReducer, {
        column: null,
        data: tableData,
        direction: null,
      })
    const { column, data, direction } = state

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {labels?.map((l,i) => 
                            <TableCell
                            key={i}
                            sorted={column === l ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: l })}
                            >
                                {l}
                            </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        range(rowCount).map(row => 
                            <TableRow key={row}>
                                {
                                    range(colCount).map(col => 
                                        <TableCell key={col}>{data[row][labels[col]]}</TableCell>
                                    )
                                }
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const range = limit => {
    let a = []
    let i = 0
    while (i < limit) {
        a.push(i)
        i ++
    }
    return a
}

export default DataTable;