import { gql } from '@apollo/client';
import React, { useState } from 'react';
import './App.css';
import RecordSearch from './RecordSearch';
import { Container, ImageList, ImageListItem, Pagination } from '@mui/material';

const PAGE_SIZE = 6

const STORIES = gql`
query ScrollStories($first: Int, $limit: Int) {
  observation(criteria: {limit:$limit, first: $first, contentType:"image/*", anchorRel: "CARD"}) {
    observations {
      id
      title
      anchor
    }
    countReturned
    countAvailable
  }
}
`;

const CardScroll = props => {
  const [observations, setObservations] = useState(null)
  const [page, setPage] = useState(1)
  const searchParams = new URLSearchParams(window.location.search);
  const subjectId = searchParams.get('subjectId');

  const totalPages = observations ? Math.max(1, Math.ceil(observations.countAvailable / PAGE_SIZE)) : 0;

  const filters = {contentType: "image/*"}

  if (subjectId) {
    filters.subjectId = subjectId;
  }

  return (<div style={{textAlign: 'center'}}>
         <RecordSearch 
            filters={filters} 
            // variables={{storiesOnly: true}}
            client={props.client} 
            page={page} pageSize={PAGE_SIZE}
            onUpdate={(o, d) => {
              setObservations(o); 
              document.getElementById('main').scrollTo(0,0);
              if (d) {
                setPage(1)
              }
            }} 
            />
        <Container>
        <ImageList sx={{ width: 500, height: 450 }} variant="woven" cols={3} gap={8}>
        {observations?.observations?.map(o => (
            <ImageListItem key={o.id}>
              <img 
                src={`${process.env.REACT_APP_REST_URI}/record/${o.id}/content`} 
                alt={`${o.title}`}
                href={o.anchor ? `/report/${o.anchor}` : null}
                />
            </ImageListItem>
            )
        )}
        </ImageList>
        <Pagination page={page} count={totalPages} onChange={(e,v) => setPage(v)} />
        </Container>
      </div>)
}

export default CardScroll;