import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validate from './Validate';
import Hideable from './Hideable';
import SignInThirdParty from './SignInThirdParty';
import { Alert, Box, Button, Container, Stack, TextField } from '@mui/material';

const REGISTER = gql`
mutation Register($name:String, $email:String!, $password:String!) {
  register(input: {name:$name, email:$email}, password: $password) {
    created
  }
}`
;

const Register = props => {
  const [values, setValues] = useState({name: '', email: '', password: ''});
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const handleChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  document.title = `navscout - Register`;

  return <Container>
      <h3>Let's get started.</h3>
      In order to monitor your travel, you will need to create a free account.<br/>
      Enter your email address and a password so that you will be able to sign in.<br/>
      You can optionally provide your name so that emails will be addressed to you.
      <SignInThirdParty account={props.account} client = {props.client} />
      <Hideable hidden={error === null}>
        <Alert severity='error'>Unable to register with the provided account details</Alert>
      </Hideable>
      <form fluid="true" onSubmit={e => {
          e.preventDefault();
          register(props.client, values, navigate, setError);
        }} >
        <Stack>
        <TextField name='email' placeholder='Email' value={values.email} onChange={handleChange} />
        <TextField type='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} />
        <TextField name='name' placeholder='Name (Optional)' value={values.name} onChange={handleChange} />
        <Box textAlign='center'>
          <Button
              onClick={() => register(props.client, values, navigate, setError)}
              disabled={!Validate.notBlank(values.email, values.password)}
              >Register</Button>
        </Box>
        </Stack>
      </form>
    </Container>;
}

const register = (client, values, navigate, setError) => {
  console.log('register ', values);
  client.mutate({
    mutation: REGISTER,
    variables: values,
    refetchQueries: ['Account']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Registration failed');
    } else {
      navigate('/');
    }
  }).catch(e => {
    console.error("Unable to register", e)
    setError(e)
  });
}

export default Register;