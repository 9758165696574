import React, { useState } from 'react';
import Hideable from './Hideable';
import LocationInput from './LocationInput';
import gql from 'graphql-tag';
import TravelDeleteButton from './TravelDeleteButton';
import Util from './Util';
import { Alert, Box, Button, ButtonGroup, Collapse, FormControlLabel, IconButton, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import { ArrowCircleDown, ArrowCircleUp, RemoveCircleOutline } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


const ADD_TRAVEL = gql`
mutation AddTravel($departure:Date,$route:[LocationInput],$speedLimit:Float) {
	addTravel(input:{departure:$departure, route:$route,navigationOptions:{speedLimit:$speedLimit}}) {
    id
  }
}`
  ;

const UPDATE_TRAVEL = gql`
mutation UpdateTravel($id: String!, $departure:Date,$route:[LocationInput],$speedLimit:Float) {
	updateTravel(id: $id, input:{departure:$departure, route:$route,navigationOptions:{speedLimit:$speedLimit}}) {
    id
  }
}`
  ;

const SET_ASSIGNMENT = gql`
mutation SetAssignment($travel:String!,$person:String!,$task:Task!) {
	setTravelAssignment(travel:$travel,person:$person,task:$task)
}`
  ;

const MAX_ROUTE_LENGTH = 4

const toMetric = (value, unit) => {
  if (unit === 'mph') {
    return value * 1.60934
  }

  return value
}

const toImperial = (value, unit) => {
  if (unit === 'kmph') {
    return value / 1.60934
  }

  return value
}

const parseDate = str => {
  if (str) {
    return new Date(str)
  }
  return null
}

const first = list => {
  if (list) {
    return list[0]
  }
  return null
}

const last = list => {
  if (list) {
    return list[list.length - 1]
  }
  return null
}

const createRoute = props => {
  const route = props?.travel?.route;
  if (route && route.length > 0) {
    return route.map(step => { return { label: step.label, id: Util.randomId() } })
  }
  return [
    { label: props.origin || '', id: Util.randomId() },
    { label: props.destination || '', id: Util.randomId() }
  ]
}

const routeIsIncomplete = route => {
  return !route
    || route.length < 2
    || route.filter(step => !step || !step.label).length > 0
}

const TravelForm = props => {
  let [departTime, setDepartTime] = useState(dayjs(new Date(props.travel?.departure)) || dayjs());
  let [leaveNow, setLeaveNow] = useState(!props.travel?.departure);
  let [driver, setDriver] = useState(null);
  let [showAdvanced, setShowAdvanced] = useState(props.travel?.speedLimit ? true : false);
  let [limitSpeed, setLimitSpeed] = useState(props.travel?.speedLimit);
  let [speedLimit, setSpeedLimit] = useState(Math.round(toImperial(props.travel?.speedLimit, 'kmph')) || 65);
  let [speedLimitUnit, setSpeedLimitUnit] = useState('mph');
  let [route, setRoute] = useState(createRoute(props))

  const accountPaid = Util.accountIsPaid(props.account)

  const submit = () => {
    saveTravel(
      props.client,
      props.travel?.id,
      route,
      leaveNow ? new Date() : departTime,
      driver,
      setSpeedLimit ? toMetric(speedLimit, speedLimitUnit) : null,
      props.onSave
    );
  }

  return <div>
    <form>
      <Stack spacing={2}>
      {route.map((step, i) => {
        return (<Stack direction="row" spacing={1} alignItems="center">
          <LocationInput key={step.id} id={`step${i}`}
            placeholder={i === 0 ? 'Your location' : i === route.length - 1 ? 'Destination' : 'Pass-through location'}
            location={step?.label}
            onChange={l => setRoute([...route.slice(0, i), { label: l?.label || '', id: step.id }, ...route.slice(i + 1)])}
            client={props.client}
          />
          &nbsp;
          {/* Up arrow */}
          <IconButton
            className={i === 0 ? 'disabled clickable' : 'clickable'}
            onClick={e => {
              e.preventDefault()
              setRoute([...route.slice(0, i - 1), route[i], route[i - 1], ...route.slice(i + 1)])
            }}
          >
            <ArrowCircleUp />
          </IconButton>
          {/* Down arrow */}
          <IconButton
            icon='arrow down'
            className={i === route.length - 1 ? 'disabled clickable' : 'clickable'}
            onClick={e => {
              e.preventDefault()
              setRoute([...route.slice(0, i), route[i + 1], route[i], ...route.slice(i + 2)])
            }}
          >
            <ArrowCircleDown />
          </IconButton>
          {/* Delete */}
          <IconButton
            icon='delete'
            className={i === 0 || i === route.length - 1 ? 'disabled clickable' : 'clickable'}
            onClick={e => {
              e.preventDefault()
              setRoute([...route.slice(0, i), ...route.slice(i + 1)])
            }}
          >
          <RemoveCircleOutline />
          </IconButton>
        </Stack>)
      })}
      <Box>
      <Button
        disabled={route.length === MAX_ROUTE_LENGTH}
        onClick={e => {
          e.preventDefault()
          if (!accountPaid) {
            return
          }
          setRoute([...route.slice(0, route.length - 1), { label: '', id: Util.randomId() }, route[route.length - 1]])
        }}>
        Add Step
      </Button>
      </Box>
      <Hideable hidden={accountPaid}>
        <Alert severity='info'>
          You will need to <a href='/account'>upgrade your account</a> to add steps.
        </Alert>
      </Hideable>

      <Hideable hidden={props.travel?.id}>
        <FormControlLabel
          control={<Switch checked={leaveNow}
            onChange={e => setLeaveNow(!leaveNow)}
          />}
          label='Leave Now'
        />
      </Hideable>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Collapse in={!leaveNow} timeout={100}>
          <DateTimePicker
            label="Departure Time"
            value={departTime}
            onChange={setDepartTime}
            renderInput={(params) => (
              <TextField {...params} id="departTime" placeholder="Time" />
            )}
          />
        </Collapse>
      </LocalizationProvider>

      <Hideable hidden={props.basic}>
        <FormControlLabel
          control={<Switch checked={showAdvanced}
            onChange={e => setShowAdvanced(!showAdvanced)}
          />}
          label='Advanced Options'
        />
        <Collapse in={showAdvanced} timeout={100}>
          <div>
            <FormControlLabel
              control={<Switch checked={limitSpeed}
                onChange={e => setLimitSpeed(!limitSpeed)}
              />}
              label='Limit Speed'
            />
            <TextField variant='outlined' type='number' value={speedLimit} disabled={!limitSpeed} onChange={(e, d) => setSpeedLimit(d.value)} />
            <Select
              disabled={!limitSpeed}
              value={speedLimitUnit}
              onChange={(e, d) => setSpeedLimitUnit(d.value)}>
              <MenuItem value='mph'>Miles Per Hour</MenuItem>
              <MenuItem value='kmph'>Kilometers Per Hour</MenuItem>
            </Select>
          </div>

        </Collapse>
      </Hideable>

      <Hideable hidden={props.account}>
        <Alert severity='warning' warning attached='bottom'>
          You will need to <a href='/sign-up'>register</a> or <a href='/sign-in'>sign-in</a> to get a forecast for your route.
        </Alert>
      </Hideable>

      <ButtonGroup spacing={2}>
        <Button
          onClick={submit}
          variant='contained'
          disabled={props.loading || !props.account || routeIsIncomplete(route)}
        >{props.travel?.id ? 'Update' : 'Travel'}</Button>
        &nbsp;
        <Hideable hidden={!props.travel?.id}>
          <TravelDeleteButton
            client={props.client}
            id={props.travel?.id}
            onDelete={props.onDelete}
          />
        </Hideable>
      </ButtonGroup>

      </Stack>
    </form>
  </div>;
}

const saveTravel = (client, id, route, departure, driver, speedLimit, onSave) => {
  route = route.map(pt => ({ label: pt.label }))
  client.mutate({
    mutation: id ? UPDATE_TRAVEL : ADD_TRAVEL,
    variables: { id, route, departure, speedLimit },
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (data.data.addTravel === null) {
      console.log('Add travel failed');
    } else {
      console.log('Updated travel')
      if (driver) {
        setAssignment(client, data.data.addTravel.id, driver, 'DRIVER', () => { });
      } else {
        //close
      }
      onSave?.(data.data.addTravel)
    }
  });
}

const setAssignment = (client, travel, person, task, then) => {
  client.mutate({
    mutation: SET_ASSIGNMENT,
    variables: { travel, person, task },
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (data.data.setTravelAssignment === null) {
      console.log('Set assignment failed');
    } else {
      then();
    }
  });
}

export default TravelForm;