import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TravelForm from './TravelForm';
import Lead from './Lead';
import { Alert, Box, Collapse, Grid2, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';


const Home = props => {
  document.title = `navscout`;
  const [dismissUpdateNotification, setDismissUpdateNotification] = useState(false);
  const navigate = useNavigate();

  return (
    <Box>
      <h1>navscout</h1>
      <Collapse in={window.notifyNewUpdate && !dismissUpdateNotification}>
            <Alert
            action={
                  <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                        window.notifyNewUpdate = false;
                        setDismissUpdateNotification(true)
                  }}
                  >
                  <Close fontSize="inherit" />
                  </IconButton>
            }
            sx={{ mb: 2 }}>
                  A new update is available, close the application to update it.
            </Alert>
      </Collapse>
      <p style={{fontSize: '1.5em'}}>
      Get a weather forecast for your drive.
      </p>
      <TravelForm 
            basic={true} 
            client={props.client} 
            account={props.account} 
            onSave={travel => {navigate(`/drive/${travel.id}`)}}
            />
      <br />
      <p>   
            <h2>View weather changes on the travel map</h2>

            <div style={{width: '400', height: '464'}}>
                  <picture>
                        <source type="image/avif" srcset="/image/home/overview.avif" />
                        <img 
                              src='/image/home/overview.png' 
                              alt='A weather forecast for the users drive and a depiction of the conditions on a map'
                              width='400'
                              height='464'
                              />
                  </picture> 
            </div>
            <p>
                  Use the interactive travel map to pinpoint exactly where weather hazards are expected to occur along your route. This tool offers 
                  a detailed view of how conditions like high winds, heavy rain, or snow will transition across different segments of your journey. 
            </p>
      </p>
      <br />
      <p>   
            <h2>Plan your travel with navscout AI</h2>

            <p>
                  Discover your next adventure with navscout AI, your ultimate travel companion! Effortlessly explore potential destinations as navscout AI compares city conditions, highlights key attractions, and curates personalized insights just for you. Dive into stunning city visuals or let navscout AI craft a custom map to guide your journey. Say goodbye to tedious travel planning—navscout AI makes it fast, fun, and tailored to your wanderlust!
                  <br />
                  <a href='/chat'>Try it now</a>
            </p>
      </p>
      <br />
      <p>
            <h2>Detect hazards on your route</h2>
            <div className='shadow banner'>
                  <Lead 
                        title='Low Visibility'
                        content={(
                              <span>
                                    Low visibility detected from 12:44 to 3:30.
                              </span>
                        )}
                        uri=''
                        />
            </div>
            <br/>
            navscout can detect hazards on your route such as high wind speeds, crosswinds, snow accumulation, glare, and more. It also 
            alerts you to icy roads, heavy rain, and dense fog, ensuring you're prepared for adverse weather conditions.
      </p>
      <br />
      <p>
            <h2>Tell at a glance how the weather will change</h2>
            <div className='shadow banner'>
                  <Lead 
                        title='Weather Summary'
                        content={(
                              <span>
                                    Rain. The temperature will drop to 41&deg;F. 79% chance of rain.
                              </span>
                        )}
                        uri=''
                        />
            </div>
            <br/>
            The navscout weather forecast feature provides you with an immediate understanding of how weather conditions will evolve, 
            allowing you to plan your trip accordingly. It displays at-a-glance information on expected changes in temperature, precipitation, 
            and wind, ensuring you can hit the road without unnecessary delays.
      </p>
      <br />
      <p>
            <h2>View weather changes on the travel map</h2>
            <br/>
            Use the interactive travel map to pinpoint exactly where weather hazards are expected to occur along your route. This tool offers 
            a detailed view of how conditions like high winds, heavy rain, or snow will transition across different segments of your journey. 
      </p>
      <br />
      <Grid2 container spacing={2}>
                  <Grid2 size={4}>About</Grid2>
                  <Grid2 size={4}>App</Grid2>
                  <Grid2 size={4}>Social</Grid2>
                  <Grid2 size={4}>
                        <a href='https://blog.navscout.com'>Blog</a><br/>
                        <Link to='/about/privacy'>Privacy Policy</Link><br/>
                        <Link to='/about/terms-of-service'>Terms of Service</Link><br/>
                        <a href='mailto:contact@navscout.com'>Contact</a><br />
                  </Grid2>
                  <Grid2 size={4}>
                        <a href='https://store.app/navscout'>store.app</a>
                  </Grid2>
                  <Grid2 size={4}>
                        <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'>X</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'>Instagram</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'>Facebook</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.youtube.com/@navscout'>Youtube</Link><br/>
                  </Grid2>
      </Grid2>
    </Box>
  )
}


export default Home;