import React, { useState } from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import { Box, Button, Container, LinearProgress, Stack } from '@mui/material';
import LocationInput from './LocationInput';
import { Radar } from '@mui/icons-material';
import SubjectContext from './SubjectContext';
import AutoContent from './AutoContent';
import Hideable from './Hideable';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

const SCAN_PLACE = gql`
query ScanPlace($label:String) {
  scanPlace(place: {label:$label}, criteria:{contentTypeExclusion:"image/*",scanType:STATIC,speed:FULL}) {
    observations {
      title
      contentType
      content
      sensor
    }
  }
}`;

const Places = props => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const name = searchParams.get('name');

  const [place, setPlace] = useState(name)
  const [records, setRecords] = useState([])

  const endsWith = (a, b, value) => {
    const aMatch = a?.endsWith(value)
    const bMatch = b?.endsWith(value)

    if (aMatch) {
      return -1
    } else if (bMatch) {
      return 1
    } else {
      return 0
    }
  }

  const firstNonZero = (comparators) => {
    for (let i = 0; i < comparators.length; i++) {
      var result = comparators[i]()
      if (result != 0) {
        return result
      }
    }
    return 0
  }

  const {loading, error, data, refetch} = useQuery(
    SCAN_PLACE,
    {
      client: props.client,
      skip: !name || name !== place,
      variables: { label: place },
      notifyOnNetworkStatusChange: true,
      onCompleted: data => { 
        var newRecords = data.scanPlace.observations || []
        newRecords = newRecords.filter(r => r.content !== null && r.content !== "" && r.sensor !== "com.navscout.main.assess.place.PlaceRecordAggregation")

        newRecords.sort((a, b) => {
          return firstNonZero([
            () => endsWith(a.sensor, b.sensor, "PlaceAssessmentReport"),
            () => endsWith(a.sensor, b.sensor, "WeatherSensor"),
            () => a.title?.localeCompare(b.title)
          ])
        })

        setRecords(newRecords)
      }
    }
  )

  const doScan = () => {
    if (place) {
      if (place !== name) {
        console.log("Navigate")
        navigate(`/place?name=${place}`)
      } else {
        console.log("Refetch")
        refetch()
      }
    }
  }

  document.title = 'navscout - ' + (place || 'Place scan');
  return (<Container>
    <Stack>
    <LocationInput 
      client={props.client}
      label="Place" 
      location={place}
      onChange={l => setPlace(l?.label)}
      />
    <Box>
      <Button onClick={doScan} startIcon={<Radar />}>Scan</Button>
    </Box>
    {loading ? <LinearProgress /> : ""}
    <Stack>
      {records.map((record, i) => (
        <Box key={i}>
          <h2>{record.title}</h2>
          <AutoContent
                client={props.client}
                content={record?.content}
                contentType={record?.contentType}
                />
          <Hideable hidden={!record?.id}>
            <SubjectContext subject={{type: 'RECORD', id: record?.id, label: record?.title}} />
          </Hideable>
          <hr/>
        </Box>
      )
    )}
    </Stack>
    </Stack>
  </Container>)
}



export default Places;