import React from 'react';
import gql from "graphql-tag";
import { Button } from '@mui/material';
import { Check, NotInterested, Pending } from '@mui/icons-material';

const RESCAN_TRAVEL = gql`
mutation RescanTravel($id: String!) {
    rescanTravel(id:$id)
}
`;

const TravelRescanButton = props => {
    const allowNow = !props.scanning && !props.complete;
    const allowLater = !props.complete

    return (
            <Button
                variant="contained"
                disabled={!allowNow}
                onClick={e => {
                    e.preventDefault()
                    rescanTravel(props.client, props.id, props.onCompleted)
                }}
                endIcon={allowNow ? <Check /> : allowLater ? <Pending /> : <NotInterested />}
                >
                    Re-scan
            </Button>
    )
}

const rescanTravel = (client, id, then) => {    
    client.mutate({
      mutation: RESCAN_TRAVEL,
      variables: {id},
      refetchQueries: ['TravelById']
    }).then(data => {
      if (!data.data.rescanTravel) {
        console.log('Rescan travel failed');
      }
      console.log('Rescan travel succeeded')
      then?.(id)
    });
}

export default TravelRescanButton;