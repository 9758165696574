import React from 'react';
import Dataset from './Dataset';
import DatasetRisk from './DatasetRisk';
import MultiTimeline from './MultiTimeline';
import Util from './Util';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import DataTable from './DataTable';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const AutoContent = props => {
    var content = props.content;
    
    if (typeof content === 'string' && props.contentType.endsWith('+json')) {
        content = JSON.parse(content);
    }

    if (props.contentType === 'application/vnd.dataset+json') {
        var renderer = Dataset.renderer(content);

        if (renderer === 'TIME_MULTI') {
            return <MultiTimeline 
                    dataSet={content}
                    />
        } else if (renderer === 'RISK') {
            return <DatasetRisk
                    dataset={content}
                    chartType="bar"
                    x="Factor"
                    y="Order"
                    label="Risk"
                    yAxisLabels={[
                        "Insufficient Data", "Not Applicable", "No Rating", "Very Low", "Low",
                        "Relatively Low", "Relatively Moderate", "Relatively High", "High"
                    ]}
                />
        } else if (renderer === 'MAP') {
            return <DataTable data={content} />
        } else if (renderer === 'TABLE') {
            return <DataTable data={content} />
        } else if (renderer === 'VALUE') {
            var value = content?.data[0][0]
            if (typeof(value) == 'number') {
                value = value.toFixed(1);
            }
            return `${value} ${content?.units[0]}`;
        } else if (renderer === 'MULTI_VALUE') {
            return (<TableContainer component={Paper}>
                <Table>
                <TableBody>
                    {content?.data?.map((d, i) => (<TableRow key={i}>
                        <TableCell>{content.labels[i]}</TableCell>
                        <TableCell>{d}{content?.units ? ` ${content.units[i]}` : ""}</TableCell>
                    </TableRow>)
                    )}
                </TableBody>
            </Table>
            </TableContainer>);
        } else {
            return Util.capitalize(renderer);
        }
    } else if (props.contentType === 'text/markdown') {
        return <div><ReactMarkdown>
            {props.content}
        </ReactMarkdown></div>
    } else if (props.contentType === 'text/html') {
        return <div dangerouslySetInnerHTML={{__html: props.content}} />
    }

    return <p>{props.content}</p>;
}

export default AutoContent;