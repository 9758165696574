import React from 'react';

const Lead = props => {
    return (
        <div style={{margin: '1em 0 1em 0'}}>
            <h4>{props.uri ? (<a href={props.uri}>{props.title}</a>)
             : (<span className='link'>{props.title}</span>)
             }</h4>
            {props.content}
        </div>
    );
}

export default Lead;