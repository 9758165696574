import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AdContent from './AdContent';
import AutoContent from './AutoContent';
import Extent from './Extent';
import Hideable from './Hideable';
import TimeUtil from './TimeUtil';
import Util from './Util';
import Gallery from './Gallery';
import SubjectContext from './SubjectContext';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Button, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Edit } from '@mui/icons-material';

const ObservationContent = props => {
    const navigate = useNavigate();

    const [showDetails, setShowDetails] = useState(false);
    var observation = props.observation;
    
    if (!observation) {
        return (<Alert severity='error'>
            <AlertTitle>Content not available</AlertTitle>
            The requested content is not available, it may have expired or been removed.
          </Alert>)
    }
    const editable = (observation?.account?.id === observation.reporter || props?.account?.roles?.includes("POWER"));

    return <Stack> 
                <Gallery client={props.client} record={observation} />
                <AutoContent
                    client={props.client}
                    content={observation?.content}
                    contentType={observation?.contentType}
                    />
                { observation?.tags?.length ? (<p>{observation.tags.map(tag => <Chip sx={{marginRight: '.5em'}} color='blue' label={tag} variant='contained' />)}</p>) : ""}
                { observation?.subjects?.map(s => <SubjectContext key={s.id} subject={s} />) }
                <AdContent id='ad' />
                <a color='blue'
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(observation.title)}&url=${encodeURIComponent(window.location.href)}`}
                    data-size="large"
                    target='__blank'
                    >Share</a>
                <Hideable hidden={!editable}>
                    <Box>
                    <Button startIcon={<Edit />} onClick={() => navigate(`/report/edit/${observation.id}`)}>Edit</Button>
                    </Box>
                </Hideable>
                <Accordion>
                    <AccordionSummary>Details</AccordionSummary>
                    <AccordionDetails><Details observation={observation} /></AccordionDetails>
                </Accordion>  
            </Stack>
}

const Details = props => {
    const observation = props.observation;

    return (<TableContainer>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell colSpan='2'>Details</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell>Occurred</TableCell>
                <TableCell>{TimeUtil.range(observation?.start, observation?.end)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Impact</TableCell>
                <TableCell><Extent value={observation.extent} /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Assets</TableCell>
                <TableCell><ul>{observation?.affectedAssets?.map(a => <li key={a.id}><Link to={`/place/${a.id}`}>{a.label}</Link></li>)}</ul></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Recorded</TableCell>
                <TableCell>{TimeUtil.longTime(observation?.created)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Modified</TableCell>
                <TableCell>{TimeUtil.longTime(observation?.modified)}</TableCell>
            </TableRow>
            <Hideable hidden={observation?.uri === null}>
                <TableRow>
                    <TableCell>More Information</TableCell>
                    <TableCell><a target='_blank' rel='noopener noreferrer' href={observation?.uri}>{Util.hostname(observation?.uri)}</a></TableCell>
                </TableRow>
            </Hideable>
        </TableBody>
    </Table>
    </TableContainer>)
}

export default ObservationContent;