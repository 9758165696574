import gql from 'graphql-tag';
import React, { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Hideable from './Hideable';
import TimeUtil from './TimeUtil';
import moment from 'moment';
import AdContent from './AdContent';
import AutoContent from './AutoContent';
import { useQuery } from '@apollo/client';
import TravelDeleteButton from './TravelDeleteButton';
import TravelRescanButton from './TravelRescanButton';
import { Breadcrumbs, Link, Skeleton, Stack } from '@mui/material';
import { Map } from '@mui/icons-material';

const TRAVEL_BY_ID = gql`
  query TravelById($travelId: String!, $first: Int, $limit: Int) {
      observation(criteria: {subjectId: $travelId, first: $first, limit: $limit, sensor: "com.navscout.main.assess.travel.TravelAssessmentReport"}) {
          countReturned
          countAvailable
          observations {
              id
              modified
              title
              category
              type
              start
              end
              contentType
              content
          } 
      }
      travelInformation(travel: $travelId) {
        id
        created
        label
        departure
        arrival
        status
      }
  }`;

const isLoaded = data => {
    if (typeof data === 'undefined') {
        return false;
    }
    var count = data?.observation?.countReturned
    var loaded = typeof count !== undefined && count > 0
    var scanning = data?.travelInformation?.status === "SCANNING"
    return loaded && !scanning
}

const Travel = props => {
    const params = useParams();
    const [done, setDone] = useState(false)
    const [travelData, setTravelData] = useState(null)

    const { loading, error, data, refetch } = useQuery(
        TRAVEL_BY_ID,
        {
            client: props.client,
            variables: { name: props.name, travelId: params.id, first: 1, limit: 5 },
            skip: (!params.id && !props.name),
            fetchPolicy: 'network-only',
            //Necessary to trigger oncompleted after refetch
            notifyOnNetworkStatusChange: true,
            onCompleted: d => { 
                setTravelData(d)
                if (!isLoaded(d)) {
                    console.log("Scanning is not done")
                    setTimeout(reload, 2000)
                } else {
                    console.log("Scanning is done")
                    setDone(true)
                }
            }
        })

    const reload = () => {
        console.log("Reload")
        if (done) {
            console.log("Done reloading")
            return
        }
        refetch()
    }
    
    useEffect(() => {
        console.log("Start reload"); 
        reload();
    }, [])

    if (error) {
        return "Error: " + error
    }

    if (!data || (!data.observation && !data.observation)) {
        return <span>You are not subscribed to any travel.</span>;
    }

    return (<div>
        <TravelContent
            data={data}
            client={props.client}
            id={params.id}
            onCompleted={id => console.log("Initiated re-scan for " + id)}
            onRescan={() => {
                console.log("Rescan initiated")
                setDone(false)
                reload()
            }}
            scanning={!done || loading}
            complete={travelData?.travelInformation?.status === 'COMPLETE'}
            />
        </div>)
}

const parseObservations = obs => {
    obs = obs || { countAvailable: 1, observations: [] };

    obs = ({
        ...obs,
        observations: obs.observations.map(o => ({
            ...o,
            content: (typeof (o?.content) === 'string' && o?.contentType?.endsWith("+json")) ? JSON.parse(o.content) : o.content
        }))
    })

    return obs;
}

const TravelContent = props => {
    const data = props.data;
    const obs = parseObservations(data.observation)
    const contextRef = createRef();
    const MAX_SCAN_TIME_MINUTES = 5;
    const travelAgeMinutes = ageMinutes(data.travelInformation?.created);
    const routing = travelAgeMinutes < MAX_SCAN_TIME_MINUTES && obs?.observations?.length === 0;
    const scanning = false;
    const navigate = useNavigate();
    const observation = data?.observation?.observations?.[0];

    document.title = `navscout - ${data?.travelInformation?.label}`;
    return <div ref={contextRef}>
        <Breadcrumbs>
            <Link href='/drive'>Drive</Link>
            <Link href={`/drive/${data.travelInformation.id}`}>{data?.travelInformation?.label || 'My Trip'}</Link>
        </Breadcrumbs>
        <br/>
        <h1>
            {data?.travelInformation?.label || props.name || 'Your Trip'}
        </h1>
        <Hideable hidden={!data?.travelInformation?.departure}>
            <h3>{TimeUtil.range(data?.travelInformation?.departure, data?.travelInformation?.arrival)}</h3>
        </Hideable>
        <Hideable hidden={!data?.travelInformation?.id}>
            {/* <ButtonGroup> */}
            {/* <Button icon='edit' onClick={() => navigate(`/drive/edit/${data.travelInformation.id}`)} /> */}
            <TravelRescanButton
                client={props.client}
                id={data?.travelInformation?.id}
                onCompleted={props.onRescan}
                scanning={props.scanning}
                complete={props.complete}
                />    
            <br/> <br/>
            <TravelDeleteButton 
              client={props.client} 
              id={data?.travelInformation?.id}
              onDelete={() => navigate('/drive')}
              />
              {/* </ButtonGroup> */}
              <br/>
        </Hideable>

        <AdContent id='ad' />
        <Hideable hidden={!routing && !scanning}>
            <h2>This route is being scanned.</h2>
            <Stack>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Stack>
        </Hideable>
        <br/>
        <Hideable hidden={routing || scanning}>
            <h3><Map /><a href={`/drive/${props.id}/details`}>Map / Details</a></h3>
        </Hideable>
        <AutoContent
            client={props.client}
            content={observation?.content}
            contentType={observation?.contentType}
        />
    </div>
}

const ageMinutes = t => moment().diff(moment(t), 'minutes')

export default Travel;