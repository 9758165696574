import React, { useRef, useState } from 'react';
import gql from 'graphql-tag';
import './App.css';
import Util from './Util';
import TimeUtil from './TimeUtil';
import InlineContent from './InlineContent';
import SubjectContext from './SubjectContext';
import { useQuery } from '@apollo/client';
import Hideable from './Hideable';
import AccountPicture from './AccountPicture';
import { Button, ButtonGroup, Chip, Paper, Stack, TextField, Tooltip } from '@mui/material';
import { LocationOnRounded, Paid, Refresh } from '@mui/icons-material';
import Confirm from './Confirm';

const USER_LOCATION_COOKIE = "USER_LOCATION"

const END_CONVERSATION = gql`
mutation EndConversation($id: String!) {
  endConversation(id: $id)
}
`;

const EVALUATE_PROMPT = gql`
mutation EvaluatePrompt($subject: String, $message:String!) {
  speak(subject: $subject, message:$message) {
    id
    created
    updated
    content {
      participantType
      content
      contentType
      remoteContent
      subjects {
        id
        type
        label
      }
      time
    }
  }
}
`;

const GET_CONVERSATION = gql`
query Conversation($subject:String) {
  conversation(subject:$subject) {
    id
    created
    content {
      participantType
      content
      contentType
      remoteContent
      subjects {
        id
        type
        label
      }
      time
    }
    account
  }
  getSubject(id:$subject) {
    type
    label
    id
  }
}
`;

const GET_LOCATION = gql`
  query GetLocation {
    geolocate {
      location {
        label,
        country,
        countryCode,
        adminDistrict,
        adminDistrict2,
        locality,
        latitude,
        longitude,
        zoneId
      }
    }
  }
`;

const Dialog = props => {
  var events = props?.conversation?.content;
  let subjects = new Map()

  if (!events) {
    return null
  }

  events.forEach(e => {
    e.subjects.forEach(s => {
      if (s.id && !subjects.has(s.id)) {
        subjects.set(s.id, s)
      }
    });
  });

  events = events.map(event => {
    return (
      <Paper elevation={2}>
        <Stack direction="row" spacing={2}>
          <div>
            {/* {
              event.participantType === 'USER' ?
                <AccountPicture account={props.account} /> :
                event.participantType === 'AD' ?
                  <Paid /> :
                  <img src='/image/chat.jpeg' />
            } */}
          </div>
          <div>
            <Stack>
              <div>
                <b>{event.participantType === 'USER' ? 'You' : event.participantType === 'AD' ? 'Ad' : 'navscout'}</b>
                &nbsp;
                {TimeUtil.shortTime(event.time)}
              </div>
              <InlineContent observation={event} />
            </Stack>
          </div>
        </Stack>
      </Paper>
    )
  });

  subjects = Array.from(subjects.values())

  return (
    <div>
      <div id='dialogWrapper'>
        <Stack>
          {events}
        </Stack>
      </div>
      {subjects.map(s => <SubjectContext subject={s} />)}
    </div>
  )
}

const queryEvent = query => {
  return (
    {
      key: Math.random(),
      date: TimeUtil.dateTime(new Date()),
      sender: 'user',
      extraText: (
        <div>
          <p>
            {query}
          </p>
        </div>
      )
    }
  )
}



const SAMPLE_PROMPTS = [
  "What is the weather for a drive from Philadelphia to Pittsburgh?",
  "Will it rain today?",
  "What is the time in Paris?",
  "Show a picture of Seattle",
  "Show Japan on a map",
  "What is in the news in the United States?"
]

//return a random choice other than the current one
const randomSampleQuery = current => {
  let currentIndex = SAMPLE_PROMPTS.indexOf(current)
  let nextIndex = Math.floor(Math.random() * (SAMPLE_PROMPTS.length - 1))
  if (nextIndex >= currentIndex) {
    nextIndex = nextIndex + 1
  }
  return SAMPLE_PROMPTS[nextIndex]
}


const DefaultContent = props => {
  return (
    <p>Get live travel help from the navscout virtual assistant.</p>
  )
}

const endConversation = (client, id) => {
  client.mutate({
    mutation: END_CONVERSATION,
    variables: { id },
    refetchQueries: ['Conversation']
  }).then(data => {
    console.log("Ended conversation")
  }).catch(err => {
    console.error(err)
  });
}



const Assistant = props => {
  const inputReference = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const [events, setEvents] = useState([])
  const [conversation, setConversation] = useState(null)
  const [subject, setSubject] = useState(null)
  const [confirmEndOpen, setConfirmEndOpen] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [sampleQuery, setSampleQuery] = useState(randomSampleQuery())

  const appendEvents = (events, newEvents) => {
    setEvents(events.concat(newEvents))
  }

  useQuery(GET_CONVERSATION, {
    client: props.client,
    variables: { rnd: Math.floor(new Date().getTime() / 2000), subject: props?.subject?.id },
    skip: !Util.hasSession(),
    pollInterval: 2000,
    onCompleted: d => {
      setInitialLoad(true)
      if (d?.conversation) {
        if (!Util.equals(conversation, d.conversation)) {
          console.log("Conversation update", props.subject?.id)
          setConversation(d.conversation)
        } else {
          console.debug("Conversation not updated")
        }
      } else {
        setConversation(null)
        setSubject(null)
      }
      if (d.getSubject) {
        setSubject(d.getSubject)
      }
    }
  });

  const query = q => {
    appendEvents(events, [queryEvent(q)])
    props.client.mutate({
      mutation: EVALUATE_PROMPT,
      variables: { message: q, subject: props?.subject?.id },
      refetchQueries: []
    }).then(data => {
      console.log(data.data.speak)
      if (data?.data?.speak) {
        setConversation(data.data.speak)
      }
    }).catch(err => {
      console.error(err)
    });
  }

  //It is necessary to store this as a global so that defaultEvent can access it
  //If it is passed, it cannot access the value of events
  window.query = query;

  const submitQuery = q => {
    if (q) {
      query(q)
    } else {
      console.log(inputValue)
      if (!inputValue) {
        return
      }
      query(inputValue)
      setInputValue('')
    }
  }

  return (
    <div className='assistant' style={{ height: "100%" }} >
      <Hideable hidden={props.subject}>
        <Locator client={props.client} />
      </Hideable>
      {subject && (
        <h3>Discuss {subject.label}</h3>
      )}
      <Hideable hidden={props.subject || events?.length > 0}>
        <DefaultContent client={props.client} />
      </Hideable>
      {/* Dialog */}
      <Dialog subject={props.subject} conversation={conversation} events={events} style={{ flex: '1 1 0%' }} account={props.account} />
      <br />
      {/* Query Box */}
      <div id='query'>
        <form className='footer' onSubmit={e => { e.preventDefault(); submitQuery() }}>
          <Stack spacing={2}>
            <TextField
              id='nsQuery'
              ref={inputReference}
              value={inputValue}
              onChange={e => {
                e.preventDefault();
                setInputValue(e.target.value)
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }}
              onKeyDown={e => {
                if (e.key === "Enter" && e.ctrlKey) {
                  submitQuery()
                }
              }}
              placeholder='Ask a question...'
              icon={{ name: 'send', circular: true, link: true, onClick: submitQuery }}
            />
            <ButtonGroup>
              <Button onClick={() => submitQuery()}>Send</Button>
              <Confirm
                open={confirmEndOpen}
                onCancel={() => setConfirmEndOpen(false)}
                onConfirm={() => {
                  setConfirmEndOpen(false);
                  endConversation(props.client, conversation?.id);
                  setConversation(null)
                }}
                trigger={<Button
                  onClick={() => setConfirmEndOpen(true)}
                  color='secondary'
                  disabled={!conversation}>
                  End
                </Button>}
                content={<p style={{ padding: '1em' }}>
                  Do you want to end and delete this conversation?
                  <br />
                  Once a conversation is deleted it cannot be recovered.
                </p>}
              />
            </ButtonGroup>
          </Stack>
          <br />
          <Tooltip open={true} placement='right' arrow title="Try this!">
            <ButtonGroup variant='contained'>
              <Button
                startIcon={<Refresh />}
                onClick={() => {
                  setSampleQuery(randomSampleQuery(sampleQuery))
                }}
              />
              <Button id='trythis' style={{ display: 'inline' }} onClick={() => {
                submitQuery(sampleQuery)
              }}>{sampleQuery}</Button>
            </ButtonGroup>
          </Tooltip>
        </form>
      </div>
    </div>
  )
}

const Locator = props => {
  const [location, setLocation] = useState(Util.getLocationCookie());

  useQuery(GET_LOCATION, {
    client: props.client,
    skip: location,
    variables: {},
    onCompleted: d => {
      const geolocation = d.geolocate.location;
      const jsonLocation = JSON.stringify(geolocation)
      const b64Location = btoa(jsonLocation)
      Util.setCookie(USER_LOCATION_COOKIE, b64Location, 60 * 60);
      setLocation(geolocation)
    }
  });

  return (
    <Chip variant='outlined' icon={<LocationOnRounded />} label={location?.label || 'Locating'} />
  )
}

export default Assistant;