import { Box, Button, ButtonGroup, Modal, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Confirm = props => {
    return (
        <div>
            {props.trigger}
            <Modal
                open={props.open}
                onClose={props.onCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">{props.title}</Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>{props.content || 'Are you sure?'}</Typography>
                        <ButtonGroup>
                            <Button variant='contained' color='primary' onClick={() => props.onConfirm?.()}>Yes</Button>
                            <Button variant='contained' color='secondary' onClick={() => props.onCancel?.()}>No</Button>
                        </ButtonGroup>
                    </Box>
            </Modal>
        </div>
    )
}


export default Confirm;
