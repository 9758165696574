import React, { useEffect, useState } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import AppInfo from './AppInfo';
import { Accordion, AccordionDetails, AccordionSummary, Container, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Facebook, Instagram, Refresh, X, YouTube } from '@mui/icons-material';

const getGooglePlayService = async () => {
    if (!'getDigitalGoodsService' in window) {
        return false
    }
    try {
        const service = await window.getDigitalGoodsService('https://play.google.com/billing')
        return true
    } catch (error) {
        return false
    }
}

const yesNo = v => v ? 'Yes' : 'No'

const About = props => {
    const [showDebug, setShowDebug] = useState(false)
    const [googlePlaySupported, setGooglePlaySupported] = useState('?')
    const [relatedApps, setRelatedApps] = useState([])

    // getGooglePlayService().then(r => setGooglePlaySupported(r ? 'yes' : 'no'))
    useEffect(() => {
        //FIXME This causes issues on browsers that don't support related apps (old safari, firefox)
        // AppInfo.getRelatedApps().then(setRelatedApps)
    })

    return (
        <Container>
            <img src='/image/navscout-logo-512.png' />
            {/* &nbsp; */}
            {/* <a href='javascript:window.location.reload()'>Update</a> */}
            <h1>Information</h1>
            <Stack>
            <Link to='/about/privacy'>Privacy Policy</Link>
            <Link to='/about/terms-of-service'>Terms of Service</Link>
            <Link to='/report'>Reports</Link>
            <Link to='/gallery'>Gallery</Link>
            </Stack>
            <br />
            <TableContainer component={Paper}>
                <Table>
            <TableBody>
                <TableRow><TableCell>Version</TableCell><TableCell>{process.env.REACT_APP_VERSION || 'Unknown'}</TableCell></TableRow>
                <TableRow><TableCell>Platform</TableCell><TableCell>{AppInfo.getPlatform()}</TableCell></TableRow>
            </TableBody>
            </Table>
            </TableContainer>
            <br/>
            <Accordion>
                <AccordionSummary index={0} active={showDebug} onClick={() => setShowDebug(!showDebug)}>
                    App Info
                </AccordionSummary>
                <AccordionDetails active={showDebug}>
                    <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow><TableCell>Profile</TableCell><TableCell>
                                {AppInfo.getProfile()}
                                &nbsp;
                                <IconButton onClick={() => { AppInfo.setProfile(null, true) }}><Refresh /></IconButton>
                            </TableCell></TableRow>
                            <TableRow><TableCell>Display Mode</TableCell><TableCell>{AppInfo.getDisplayMode()}</TableCell></TableRow>
                            <TableRow><TableCell>User Agent</TableCell><TableCell>{AppInfo.getUserAgent()}</TableCell></TableRow>
                            {/* <TableRow><TableCell>Google Play Supported?</TableCell><TableCell>{googlePlaySupported}</TableCell></TableRow> */}
                            <TableRow><TableCell>Mobile</TableCell><TableCell>{yesNo(AppInfo.isMobile())}</TableCell></TableRow>
                            <TableRow><TableCell>Brands</TableCell><TableCell>{AppInfo.getUserAgentData()?.brands?.map(b => b.brand)?.reduce((a, b) => { return a === "" ? b : a + ", " + b }, "") || "None"}</TableCell></TableRow>
                            <TableRow><TableCell>Related Apps</TableCell><TableCell>{relatedApps?.reduce((a, b) => { return a === "" ? b : a + ", " + b }, "") || "None"}</TableCell></TableRow>
                            <TableRow><TableCell>Allow Payment</TableCell><TableCell>{yesNo(AppInfo.allowPaymentManagement())}</TableCell></TableRow>
                            <TableRow><TableCell>Android App</TableCell><TableCell>{yesNo(AppInfo.isAndroidApp())}</TableCell></TableRow>
                            {/* <TableRow><TableCell></TableCell><TableCell></TableCell></TableRow> */}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            {showDebug}
            <h1>Social</h1>
            <Link style={{ textDecoration: 'none' }} to='https://www.twitter.com/navscout/'><X /></Link>
            <Link style={{ textDecoration: 'none' }} to='https://www.instagram.com/trynavscout/'><Instagram size='big' name='instagram' /></Link>
            <Link style={{ textDecoration: 'none' }} to='https://www.facebook.com/navscout/'><Facebook size='big' name='facebook' /></Link>
            <Link style={{ textDecoration: 'none' }} to='https://www.youtube.com/@navscout'><YouTube size='big' name='youtube' /></Link>
        </Container>
    )
}

export default About;