import gql from 'graphql-tag';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TravelForm from './TravelForm';
import { useQuery } from '@apollo/client';
import Hideable from './Hideable';
import { Alert, Breadcrumbs, Link } from '@mui/material';

const GET_TRAVEL = gql`
query GetTravelDetails($id: String!) {
  travelInformation(travel: $id) {
    id
    departure
    arrival
    label
    navigationOptions {
      speedLimit
    }
    assignments {
      task
      person {
        id
        name
      }
    }
    route {
      label
    }
  }
}`;

const TravelEdit = props => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const {loading, error, data} = useQuery(GET_TRAVEL, {client: props.client, variables: {id: params.id}, skip: !params.id})
  const travel = data?.travelInformation;
  const navigate = useNavigate();

  const searchOrigin = searchParams.get('origin');
  const searchDestination = searchParams.get('destination');

  if (loading) {
    return "Loading..."
  }
  console.log(travel)

  if (!props.account) {
    return <Alert severity='info'>
      This page will provide you with a travel report including travel alerts and weather conditions for your trip{searchDestination ? ` to ${searchDestination}` : ''}.
      First you must <Link to='/sign-up'>create an account</Link> or <Link to='/sign-in'>Sign In</Link>.
    </Alert>
  }
  return (
    <div>
        <Hideable hidden={!data?.travelInformation}>
          <Breadcrumbs>
              <Link to='/drive'>Drive</Link>
              <Link to={`/drive/${data?.travelInformation?.id}`}>{data?.travelInformation?.label || 'My Trip'}</Link>
              <span>Edit</span>
          </Breadcrumbs>
        </Hideable>
        <Hideable hidden={data?.travelInformation}>
          <Breadcrumbs>
              <Link to='/drive'>Drive</Link>
              <span>New</span>
          </Breadcrumbs>
        </Hideable>
        <br/><br/>
        <TravelForm 
          onSave={travel => {navigate(`/drive/${travel.id}`)}}
          client={props.client}
          travel={travel}
          origin={travel?.route[0]?.label || searchOrigin}
          destination={travel?.route[travel?.route?.length - 1]?.label || searchDestination}
          onDelete={id => {
            navigate('/drive');
          }}
          account={props.account}          
          />
      </div>);
}

export default TravelEdit;