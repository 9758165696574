import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import Auth from './Auth';
import Hideable from './Hideable';
import LocationInput from './LocationInput';
import Util from './Util';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

const CATEGORY_QUERY = gql`
  query Categories {
    categories
  }
`;

const REPORTS = gql`
query Reports($first: Int, $limit: Int, $type: ObservationType, $reporter: String, $query: String, $modified:String, $occurring:String, $category: String, $tags: [String], $location: String, $subjectId: String, $anchorRel: String, $contentType: String, $fetchContent: Boolean!) {
    observation(criteria: {first: $first, limit: $limit, type: $type, reporter: $reporter, query: $query, modified: $modified, occurring: $occurring, category: $category, tags: $tags, location: $location, subjectId: $subjectId, anchorRel: $anchorRel, contentType: $contentType}) {
        countReturned
        countAvailable
        observations {
            anchor
            anchorRel
            id
            category
            title
            start
            end
            modified
            content @include(if: $fetchContent)
            contentType
            type
            reporter
            extent
        } 
    }
}`
  ;

const SUBJECT = gql`
query Subject($subjectId: String) {
    getSubject(id: $subjectId) {
        id
        type
        label
    }
}
`

const parseBool = (s, defaultValue) => {
  if (s === null || typeof s === 'undefined') {
    return defaultValue;
  }
  return s === "true"
}

const PAGE_SIZE = 10;


const RecordSearch = props => {
  const params = new URLSearchParams(window.location.search)
  const defaultCategory = params.get('category') || 'All';
  const defaultStoriesOnly = parseBool(params.get('storiesOnly'), false);
  const defaultLocation = params.get('location');
  const [search, setSearch] = useState(params.get('query'));
  const [location, setLocation] = useState(defaultLocation);
  const [typedLocation, setTypedLocation] = useState(defaultLocation);
  const [range, setRange] = useState('P-7D/PT1H');
  const [alerts, setAlerts] = useState(false);
  const [stories, setStories] = useState(props?.variables?.storiesOnly || defaultStoriesOnly);
  const [categories, setCategories] = useState(['All'])
  const [category, setCategory] = useState(defaultCategory);
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const [mine, setMine] = useState(false);
  const [filters, setFilters] = useState(false);
  const [subject, setSubject] = useState(null)
  const [dirty, setDirty] = useState(false)

  const updateParams = () => {
    setSearch(document.getElementById('search').value)
    setRange(document.getElementById('modified').value)
    setCategory(selectedCategory)
    setAlerts(document.getElementById('alertsOnly').checked)
    setMine(document.getElementById('myReports')?.checked || false)
    setLocation(typedLocation)
    setDirty(true)
  }

  const imageOnly = props?.filters?.contentType?.startsWith("image/") || false

  useQuery(CATEGORY_QUERY, {
    client: props.client,
    onCompleted: data => setCategories(["All"].concat(data.categories))
  });

  useQuery(SUBJECT, {
    client: props.client,
    variables: {
      subjectId: params.get('subjectId')
    },
    onCompleted: data => {
      setLocation(data?.getSubject?.label)
      setSubject(data?.getSubject)
    }
  })

  const query = {
    term: search,
    range,
    alerts,
    category,
    subjectId: subject?.id,
    reporter: mine ? props.account?.id : null,
    tags: (stories && !imageOnly) ? ["Story"] : null,
    location: Util.isDefined(subject?.id) ? null : location
  }

  useQuery(REPORTS, {
    client: props.client,
    variables: {
      type: query.alerts ? 'WARNING' : null,
      reporter: query.reporter,
      first: ((props.page - 1) * PAGE_SIZE) + 1,
      limit: props.pageSize,
      query: query?.term,
      tags: query.tags,
      subjectId: subject?.id || query.subjectId,
      modified: query.range,
      occurring: props.occurring,
      category: query.category === 'All' ? null : query.category,
      location: query.location === "" ? null : query.location,
      anchorRel: (imageOnly && stories) ? "CARD" : null,
      fetchContent: props.fetchContent ? true : false,
      ...props.filters
    },
    onCompleted: d => {
      if (d.observation) {
        props.onUpdate(d.observation, dirty)
        setDirty(false)
      }
    }
  });

  return (
    <Hideable hidden={props.hidden}>
    <form>
    <Stack spacing={2}>
      <TextField sx={{width: '100%'}} id='search' placeholder='Query' defaultValue={params.get('query')} />
      <Accordion>
        <AccordionSummary>Filters</AccordionSummary>
        <AccordionDetails>
          <Stack>
          <LocationInput id='location'
            placeholder='Location'
            location={location}
            onChange={l => {
              const s = { id: l.id, label: l.label, type: 'PLACE' }
              setTypedLocation(l.label)
              setSubject(s)
            }}
            client={props.client}
          />
          <Select
            id='category'
            label='Category'
            onChange={(e, d) => setSelectedCategory(d.value)}
            defaultValue={category}
          >
            {categories.map(c => (<MenuItem value={c}>{c}</MenuItem>))}
          </Select>
          <label for='modified'>Reported Since</label>
          <select id='modified'>
            <option value='P-1D/PT1H'>1 Day</option>
            <option value='P-7D/PT1H' selected>1 Week</option>
            <option value='P-1M/PT1H'>1 Month</option>
          </select>
          <FormControlLabel label='Stories Only' control={<Checkbox checked={stories} onChange={e => setStories(!stories)} />} />
          <FormControlLabel label='Alerts Only' control={<Checkbox id='alertsOnly' />} />
          <Hideable hidden={!Util.hasSession()}>
            <FormControlLabel label='Mine' control={<Checkbox id='myReports' disabled={!Auth.canReport(props.account)} />} />
          </Hideable>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Box>
        <Button onClick={e => { e.preventDefault(); updateParams() }}>Search</Button>
      </Box>
      </Stack>
    </form>
    </Hideable>
  )
}

export default RecordSearch;