import { Alert, Button, Modal, Stack } from "@mui/material"
import React from "react"

const NotificationDisplay = props => {
    return (
        <Modal
            open={props.notifications?.length > 0}
        >
            <Stack>
                <h1>Notifications</h1>
                <p>
                    {
                        props.notifications?.map((n, i) => {
                            return <Alert severity="error" key={i} content={n.message} />
                        })
                    }
                </p>
                <Button
                    onClick={() => {
                        props.onClose?.()
                    }}
                >
                    Dismiss
                </Button>
            </Stack>
        </Modal>
    )
}

export default NotificationDisplay;