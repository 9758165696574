import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validate from './Validate';
import { Box, Button, Container, Link, Stack, TextField } from '@mui/material';

const GET_TOKEN = gql`
mutation GetToken($email:String!) {
  requestPasswordReset(email:$email)
}`
  ;

const SignInGetToken = props => {
  const [email, setEmail] = useState('');
  let navigate = useNavigate();

  document.title = `navscout - Sign In`;

  return <Container>
    <h3>Enter your email, a one time sign in token will be sent to your email.</h3>
    <form fluid="true" onSubmit={e => {
      e.preventDefault();
      getToken(props.client, email, navigate);
    }} >
      <Stack spacing={2}>
        <TextField label='Email' name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        <Box>
          <Button
            disabled={!Validate.notBlank(email)}
            variant='contained'
          >Get Token</Button>
        </Box>
        <Link href='/sign-in'>Sign In</Link>
        <Link href='/sign-up'>Register</Link>
      </Stack>
    </form>
  </Container>;
}

const getToken = (client, email, navigate) => {
  client.mutate({
    mutation: GET_TOKEN,
    variables: { email },
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (!data.data.requestPasswordReset === null) {
      console.log('Request token failed');
    } else {
      navigate('/sign-in/token');
    }
  });
}

export default SignInGetToken;