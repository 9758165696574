import { Refresh } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

const RefreshButton = props => (
    <Button 
        onClick={e => props.client.refetchQueries({include:props.queries})}
        startIcon={<Refresh />}>
        Update
    </Button>
)

export default RefreshButton;