import { ApolloClient, InMemoryCache, createHttpLink, gql, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import About from './About';
import Account from './Account';
import './App.css';
import Chat from './Chat';
import ErrorBoundary from './ErrorBoundary';
import Home from "./Home";
import Images from './Images';
import SignIn from './SignIn';
import SignInGetToken from "./SignInGetToken";
import SignInToken from "./SignInToken";
import Places from './Places';
import Purchase from './Purchase';
import Register from './Register';
import Report from "./Report";
import ReportEdit from './ReportEdit';
import Reports from './Reports';
import Travel from "./Travel";
import TravelDetails from "./TravelDetails";
import TravelEdit from './TravelEdit';
import Travels from './Travels';
import NotificationDisplay from './NotificationDisplay';
import UploadMedia from './UploadMedia';
import AboutPrivacy from './AboutPrivacy';
import AboutTermsOfService from './AboutTermsOfService';
import AppInfo from './AppInfo';
import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';

const ACCOUNT = gql`
query Account {
  account {
    id
    name
    purchase {
      status
    }
    roles
    pictureUrl
  }
}`;

const LOGOUT = gql`
mutation Logout {
  logout
}`;

const httpLink = createHttpLink({uri: process.env.REACT_APP_API_URI, withCredentials: true})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : "",
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  }
});


const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URI,
  // link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY
  }
});

const App = props => {
  const [searchParams] = useSearchParams();
  const { loading, data } = useQuery(ACCOUNT, { client: client });
  const account = data?.account;
  const [notifications, setNotifications] = useState([])

  const onError = e => setNotifications([...[e], ...notifications])

  console.log(`App version: ${process.env.REACT_APP_VERSION}`)

  AppInfo.setProfile(searchParams.get('app.profile'))

  if (loading) {
    return null
  }

  return <ErrorBoundary>
    <div className='presentation'>
      <NotificationDisplay
        notifications={notifications}
        onClose={() => setNotifications([])}
        />
      <TopMenu account={account} client={client} />
      <div style={{ padding: '1em' }} className='main' id='main'>
        <Routes>
          <Route path='/about' element={<About client={client} />} />
          <Route path='/about/privacy' element={<AboutPrivacy client={client} />} />
          <Route path='/about/terms-of-service' element={<AboutTermsOfService client={client} />} />
          <Route path='/account' element={<Account client={client} />} />
          <Route path='/account/purchase' element={<Purchase client={client} />} />
          <Route path='/chat' element={<Chat client={client} account={account} />} />
          <Route path='/place' element={<Places account={account} client={client} onError={onError} />} />
          <Route path='/report' element={<Reports account={account} client={client} />} />
          <Route path='/report/add' element={<ReportEdit client={client} account={account} />} />
          <Route path='/report/edit/:id' element={<ReportEdit client={client} />} />
          <Route path='/report/:id' element={<Report account={account} client={client} />} />
          <Route path='/drive' element={<Travels client={client} />} />
          <Route path='/drive/new' element={<TravelEdit account={account} client={client} />} />
          <Route path='/drive/edit/:id' element={<TravelEdit account={account} client={client} />} />
          <Route path='/drive/:id' element={<Travel client={client} />} />
          <Route path='/drive/:id/details' element={<TravelDetails client={client} />} />
          <Route path='/gallery' element={<Images account={account} client={client} />} />
          <Route path='/gallery/upload' element={<UploadMedia account={account} client={client} />} />
          <Route path='/sign-in' element={<SignIn client={client} account={account} />} />
          <Route path='/sign-in/get-token' element={<SignInGetToken client={client} />} />
          <Route path='/sign-in/token' element={<SignInToken client={client} />} />
          <Route path='/sign-up' element={<Register account={account} client={client} />} />
          <Route path='/' element={<Home account={account} client={client} />} />
        </Routes>
      </div>
    </div>
  </ErrorBoundary>
}


const TopMenu = props => {
  const [mobile, setMobile] = useState(window.innerWidth < 720)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  const pages = [
    {label:'Home', link: '/'},
    {label:'Drive', link: '/drive'},
    {label:'Places', link: '/place'},
    {label:'Chat', link: '/chat'},
    {label:'About', link: '/about'},
  ];
  const anon_settings = [
    {label: 'Sign In', onClick: () => navigate('/sign-in')}
  ];
  const user_settings = [
    {label: 'Account', onClick: () => navigate('/account')},
    {label: 'Sign Out', onClick: () => logout(props.client, navigate)},
  ];
  const settings = props.account ? user_settings : anon_settings;

  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MapIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            navscout
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map(page => (
                <MenuItem key={page.label} onClick={() => {handleCloseNavMenu();}}>
                  <Typography sx={{ textAlign: 'center' }}><a style={{textDecoration: 'none'}} href={page.link}>{page.label}</a></Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <MapIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            navscout
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.label}
                onClick={() => {handleCloseNavMenu();navigate(page.link)}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={props.account?.name} src={props.account?.pictureUrl} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={() => {handleCloseUserMenu();setting.onClick?.()}}>
                  <Typography sx={{ textAlign: 'center' }}>{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const logout = (client, navigate) => {
  client.mutate({
    mutation: LOGOUT,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Sign out failed');
    } else {
      console.log('Signed out');
      navigate('/sign-in');
    }
  });
}

export default App;
