import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import UploadMediaForm from './UploadMediaForm';
import { Box, Button, Container, Modal } from '@mui/material';
import { Description, Link } from '@mui/icons-material';

const ATTACHMENTS = gql`
query EditAttachments($id:  String) {
    observation(criteria: {anchor: $id}) {
        observations {
            id
            title
            contentType
            remoteContent
        }
        countAvailable
    }
}`
    ;

const DELETE = gql`
    mutation Delete($id: String!) {
        deleteObservation(id:$id)
    }
`;

const ReportAttachments = props => {
    const [attachments, setAttachments] = useState([])

    useQuery(ATTACHMENTS, {
        client: props.client,
        variables: { id: props.id },
        skip: !props.id,
        onCompleted: data => {
            console.log("Fetch Attachments", data?.observation)
            if (data?.observation) {
                setAttachments(data.observation.observations)
            }
        }
    });

    return (
        <div>
            <ul>
                {attachments.map((r, i) => {
                    return (<li key={i}>
                        {r.title} ({r.contentType}) {r.id}
                        <a href={process.env.REACT_APP_REST_URI + `/record/${r?.id}/content`} target='__blank'><Description /></a>
                        <Link
                            name='delete'
                            onClick={e =>
                                props.client.mutate({
                                    mutation: DELETE,
                                    variables: { id: r.id },
                                    refetchQueries: ['Attachments', 'EditAttachments']
                                }).then(data => {
                                    if (data.data.addObservation === null) {
                                        console.log('Attach failed');
                                    } else {
                                        setAttachments(attachments.filter(a => a.id !== r.id))
                                    }
                                })
                            }
                        />
                    </li>)
                })}
            </ul>

            <AttachForm
                client={props.client}
                id={props.id}
                attachments={attachments}
                setAttachments={setAttachments}
            />

        </div>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AttachForm = props => {
    const [open, setOpen] = useState(false)

    return (<div>
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style}>
                <UploadMediaForm
                    client={props.client}
                    onCompleted={a => {
                        props.setAttachments([a].concat(props.attachments))
                        setOpen(false)
                    }}
                />
            </Box>
        </Modal>
        <Button onClick={() => setOpen(true)}>Attach</Button>
    </div>);
}

export default ReportAttachments;