import gql from 'graphql-tag';
import React, { useState } from 'react';
import LocationInput from './LocationInput';
import Validate from './Validate';
import { useNavigate, useParams } from 'react-router-dom';
import ReportAttachments from './ReportAttachments';
import { useQuery } from '@apollo/client';
import Hideable from './Hideable';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import Confirm from './Confirm'
import Util from './Util';

const LOAD_REPORT = gql`
query EditReport($id:  String) {
    observation(criteria: {id: $id}) {
        observations {
            id
            start
            end
            modified
            content
            contentType
            title
            category
            type
            reporter
            extent
            uri
            tags
        } 
    }
}`
  ;

const ATTACHMENTS = gql`
query Attachments($id:  String) {
    observation(criteria: {anchor: $id}) {
        observations {
            id
            title
        }
        countAvailable
    }
}`
  ;

const ADD_REPORT = gql`
  mutation AddReport($start: Date, $end: Date, $type: ObservationType, $category: String, $title: String, $content: String, $contentType: String, $uri: String, $extent: JSON, $tags: [String]) {
    addObservation(observation: {
      start: $start,
      end: $end,
      title: $title,
      contentType: $contentType,
      content: $content,
      category: $category,
      type: $type,
      extent: $extent,
      uri: $uri,
      tags: $tags
    }) {
      id
    }
  }
`;

const UPDATE_REPORT = gql`
  mutation UpdateReport($id: String!, $start: Date, $end: Date, $type: ObservationType, $category: String, $title: String, $content: String, $contentType: String, $uri: String, $extent: JSON, $tags: [String]) {
    updateObservation(id: $id, observation: {
      start: $start,
      end: $end,
      title: $title,
      contentType: $contentType,
      content: $content,
      category: $category,
      type: $type,
      extent: $extent,
      uri: $uri,
      tags: $tags
    }) {
      id
    }
  }
`;

const DELETE_REPORT = gql`
  mutation DeleteReport($id: String!) {
      deleteObservation(id: $id)
  }
`;

const LOCATION = gql`
  query Location($location: String!) {
    location(input: $location) {
      country
      adminDistrict
      adminDistrict2
      locality
    }
  }
`;

const CONTENT_TYPES = { 'Text': 'text/plain', 'Markdown': 'text/markdown' };

const REPORT_TYPES = ['INFORMATION', 'WARNING'];

const CATEGORIES = ['Aviation', 'Conflict', 'Crime', 'Disaster', 'Health', 'Transportation', 'Weather', 'Wildfire'];

const locationFromExtent = e => {
  if (!e || !e.region) return null;

  const r = e.region;

  if (r.locality) { return `${r.locality}, ${r.adminDistrict}, ${r.country}` }
  else if (r.adminDistrict2) { return `${r.adminDistrict2}, ${r.adminDistrict}, ${r.country}` }
  else if (r.adminDistrict) { return `${r.adminDistrict}, ${r.country}` }
  else if (r.country) { return `${r.country}` }
}

const ReportEdit = props => {
  const [start, setStart] = useState(dayjs().startOf('hour').add(1, 'hour'));
  const [end, setEnd] = useState(dayjs().startOf('hour').add(2, 'hour'));
  const [title, setTitle] = useState('');
  const [contentType, setContentType] = useState('text/plain');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('INFORMATION');
  const [location, setLocation] = useState('');
  const [uri, setUri] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tags, setTags] = useState(null);
  const [aiSummarize, setAiSummarize] = useState(false);
  const [aiTitle, setAiTitle] = useState(true);
  const [aiGeoTag, setAiGeoTag] = useState(true);
  const [aiMap, setAiMap] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [attachmentCount, setAttachmentCount] = useState(0);

  const { loading, error, data } = useQuery(LOAD_REPORT, {
    client: props.client,
    variables: { id: params.id },
    skip: !params.id,
    onCompleted: data => {
      var o = data?.observation?.observations[0];
      var s = o.start == null ? null : dayjs(new Date(o.start))
      var e = o.end == null ? null : dayjs(new Date(o.end))
      if (o) {
        setStart(s)
        setEnd(e)
        setType(o.type)
        setTitle(o.title)
        setContentType(o.contentType)
        setContent(o.content)
        setCategory(o.category)
        setLocation(locationFromExtent(o.extent))
        setUri(o.uri)
        setTags(o.tags?.join(", "))
        setAiTitle(!o.title)
        setAiGeoTag(o.extent === null)
      }
    }
  });

  console.log("Load attachments")

  useQuery(ATTACHMENTS, {
    client: props.client,
    variables: { id: params.id },
    skip: !params.id,
    onCompleted: data => {
      if (data?.observation) {
        setAttachmentCount(data.observation.countAvailable)
      }
    }
  });

  const complete = Validate.notBlank(start, end, content, category, type);

  const getLocation = () => {
    console.log("getLocation", location)
    if (location) {
      props.client.query({
        query: LOCATION,
        variables: { location }
      }).then(data => {
        if (data.data.location === null) {
          console.log('Get location failed')
        } else {
          submitReport({
            type: "Region",
            region: data.data.location
          });
        }
      })
    } else {
      submitReport()
    }
  }

  const submitReport = extent => {
    const tagList = tags ? tags.split(",").map(tag => tag.trim()) : [];
    if (aiSummarize) {
      tagList.push("@Summarize")
    }
    if (aiTitle) {
      tagList.push("@Title")
    }
    if (aiGeoTag) {
      tagList.push("@GeoTag")
    }
    if (aiMap) {
      tagList.push("@Map")
    }

    const variables = { id: params.id, start, end, type, category, title, content, contentType, uri, extent, tags: tagList }

    console.log(variables)

    if (params.id) {
      props.client.mutate({
        mutation: UPDATE_REPORT,
        variables: variables,
        refetchQueries: ['EditReport']
      }).then(data => {
        if (data.data.updateObservation === null) {
          console.log('Report failed');
        } else {
          navigate('/report');
        }
      });
    } else {
      props.client.mutate({
        mutation: ADD_REPORT,
        variables: { start, end, type, category, title, content, contentType, uri, extent, tags: tagList },
        refetchQueries: ['EditReport']
      }).then(data => {
        if (data.data.addObservation === null) {
          console.log('Report failed');
        } else {
          navigate('/report');
        }
      });
    }
  };

  const deleteReport = () => {
    props.client.mutate({
      mutation: DELETE_REPORT,
      variables: { id: params.id },
      refetchQueries: ['EditReport']
    }).then(data => {
      if (!data?.data?.deleteObservation) {
        console.log('Report delete failed');
      } else {
        console.log(`Deleted report ${params.id}`);
        setConfirmDelete(false);
        navigate('/report');
      }
    });
  };

  console.log("Render")

  return (
    <Box>
      <Confirm
        open={confirmDelete}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={deleteReport}
      />

      <h1>Report Information</h1>
      <Typography variant='h6'>You can {Util.accountIsPower(props.account) ? '' : 'not '}report</Typography>
      <br/>
      <form>
        <Stack spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              id="start"
              label="Start"
              value={start}
              onChange={setStart}
              renderInput={(params) => (
                <TextField {...params} id="startTime" placeholder="Time" />
              )}
            />
            <DateTimePicker
              id="end"
              label="End"
              value={end}
              onChange={setEnd}
              renderInput={(params) => (
                <TextField {...params} id="endTime" placeholder="Time" />
              )}
            />
          </LocalizationProvider>
          Content Type
          <Select
            value={contentType}
            onChange={e => setContentType(e.target.value)}>
            {Object.entries(CONTENT_TYPES).map((k, v) => { return <MenuItem value={k[1]}>{k[0]}</MenuItem> })}
          </Select>
          <TextareaAutosize rows='10' placeholder='Report information' value={content} onChange={e => setContent(e.target.value)} />
          <TextField placeholder='Report Title' value={title} onChange={e => {
            setTitle(e.currentTarget.value)
            setAiTitle(!Validate.notBlank(e.currentTarget.value))
          }} />
          Category
          <Select list='categories' placeholder='Crime, Weather, ...' value={category} onChange={e => setCategory(e.target.value)}>
            {CATEGORIES.map(c => <MenuItem value={c}>{c}</MenuItem>)}
          </Select>
          Type
          <Select
            value={type}
            onChange={e => setType(e.target.value)}
          >
            {REPORT_TYPES.map((t, i) => <MenuItem value={t}>{t}</MenuItem>)}
          </Select>

          <LocationInput id='location'
            placeholder='Miami, FL'
            location={location}
            onChange={l => {
              if (l?.label) {
                setLocation(l.label);
                setAiGeoTag(!Validate.notBlank(l.label))
              }
            }}
            client={props.client}
          />

          <TextField labelId="link-label" id='uri' placeholder='mysite.com' defaultValue={uri} onChange={e => setUri(e.currentTarget.value)} />

          <TextField labelId="tags-label" id='uri' placeholder='Comma separated tags' defaultValue={tags} onChange={e => setTags(e.currentTarget.value)} />
          <ReportAttachments client={props.client} id={params.id} />

          <div>
            <FormControlLabel label='Summarize' control={<Checkbox checked={aiSummarize} onChange={() => setAiSummarize(!aiSummarize)} />} />
            <FormControlLabel label='Title' control={<Checkbox checked={aiTitle} onChange={() => setAiTitle(!aiTitle)} />} />
            <FormControlLabel label='GeoTag' control={<Checkbox checked={aiGeoTag} onChange={() => setAiGeoTag(!aiGeoTag)} />} />
            <FormControlLabel label='Add Map' control={<Checkbox checked={aiMap} onChange={() => setAiMap(!aiMap)} />} />
          </div>
        </Stack>
      </form>
      <br />
      <Hideable hidden={!params.id}>
        <Button onClick={() => setConfirmDelete(true)} disabled={!params.id}>
          Delete
        </Button>
      </Hideable>
      <Button onClick={() => navigate('/report')}>
        Cancel
      </Button>
      <Button
        onClick={getLocation}
        positive
        disabled={!complete}
      >Submit</Button>
    </Box>
  );
}

export default ReportEdit;