import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Hideable from './Hideable';
import RefreshButton from './RefreshButton';
import Util from './Util';
import SubjectContext from './SubjectContext';
import TravelDeleteButton from './TravelDeleteButton';
import { Box, Breadcrumbs, Button, Chip, Container, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Add, Warning } from '@mui/icons-material';
import ModalText from './ModalText';

const GET_TRAVEL = gql`
query TravelInformation($range: String!){
    listTravelInformation(range: $range) {
      id
      departure
      label
      alertCount
      status
      assignments {
        task
        person {
          id
          name
        }
      }
    }
  }`
  ;

const Travels = props => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_TRAVEL, { client: props.client, variables: { range: "PT-4H/P30D" } });

  if (!Util.hasSession()) {
    return <Container>
      <h2>
        Discover what lies ahead.
      </h2>
      <p style={{ 'fontSize': 'large' }}>
        navscout can monitor weather conditions on your travel route and provide advance notifications of hazards on your path.
      </p>
      <p style={{ 'fontSize': 'large' }}>
        In order to get a travel report you must <a href='/sign-up'>get an account</a> or <a href='/sign-in'>sign in</a>.
        An account is free to open and you can subscribe to gain additional capability.
      </p>
    </Container>
  }

  if (!data) {
    return "Loading...";
  }

  document.title = `navscout - Travel`;

  return <Stack>
    <Breadcrumbs>
    <Link underline="hover" color="inherit" href="/drive">Drive</Link>
    </Breadcrumbs>
    <Box>
    <Button startIcon={<Add />} onClick={() => navigate('/drive/new')}>Plan your drive</Button>
    </Box>
    <Box>
    <RefreshButton client={props.client} queries={['TravelInformation']} />
    </Box>
    <h2>Your trips</h2>
    <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Manage</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {data.listTravelInformation.map((travel, i) =>
        <TableRow key={i} error={travel.alertCount > 0}>
          <TableCell>
            {Util.capitalize(travel.status)}
            &nbsp;
            <Hideable hidden={travel.alertCount === 0}>
              <ModalText
                trigger={<Chip color='warning' severity='warning' icon={<Warning />} label={`${travel.alertCount} alerts`} />}>
                {`${travel.alertCount} alerts`}
                </ModalText>
            </Hideable>
          </TableCell>
          <TableCell>
            {moment(travel.departure).format("MMMM DD h:mm A")}
          </TableCell>
          <TableCell>
            <SubjectContext subject={{ type: 'TRAVEL', id: travel.id, label: travel.label }} />
            {/* &nbsp;&nbsp;
            <Link to={`/drive/edit/${travel.id}`}><Icon name='edit' /></Link> */}
          </TableCell>
          <TableCell>
            <TravelDeleteButton
              client={props.client}
              id={travel.id}
              onDelete={() => navigate('/drive')}
            />
          </TableCell>
        </TableRow>
      )}
      </TableBody>
    </Table>
    </TableContainer>
  </Stack>;
}

export default Travels;