import gql from 'graphql-tag';
import React, { useState } from 'react';
import Hideable from './Hideable';
import { useQuery } from '@apollo/client';
import { Button, ButtonBase, Container, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const IMAGES = gql`
query Observation($anchor: String, $first: Int) {  
  observation(criteria: {anchor: $anchor, contentType: "image/*", first: $first, limit:1})
  { 
    observations {
        title
        id
        content
  	}
    countAvailable
    countReturned
  }
}
`;

const Gallery = props => {
    const [first, setFirst] = useState(1)
    const [last, setLast] = useState(-1)
    const { loading, error, data } = useQuery(IMAGES, {
        client: props.client,
        variables: { anchor: props.record.id, first: first },
        onCompleted: data => { setLast(data.observation.countAvailable)}
    });

    const observations = data?.observation?.observations;

    document.title = `navscout - Gallery`;

    if (observations) {
        const observation = observations[0]
        if (!observation) {
            return
        }
        let src = observation.content

        src = process.env.REACT_APP_REST_URI + `/record/${observation?.id}/content`

        return <Container textAlign='center' style={{
            position: 'relative'
            }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                }}>
            <img src={src} alt={observation.title} />
            </div>
            <Hideable hidden={observation.title === props.record.title}>
                <h1>{observation.title}</h1>
            </Hideable>
            <Hideable hidden={last <= 1}>
                <Button 
                    startIcon={<ArrowLeft />}
                    disabled={first == 1}
                    onClick={e => setFirst(first - 1)}
                >Last</Button>
                <Button
                    disabled={first == last}
                    onClick={e => setFirst(first + 1)}
                    endIcon={<ArrowRight />}
                >Next</Button>
            </Hideable>
        </Container>
    }
}

export default Gallery;