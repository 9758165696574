import { Box, Modal, Stack } from '@mui/material';
import React, { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ModalText = props => {
    const [open, setOpen] = useState(false);

    const doSetOpen = value => {
        if (props.setOpen === undefined) {
            setOpen(value)
        } else {
            props.setOpen(value)
        }
    }

    const doGetOpen = () => {
        if (props.open === undefined) {
            return open
        }
        return props.open
    }

    return (
        <span>
            <span className='link' onClick={() => {doSetOpen(true)}}>{props.trigger}</span>
            <Modal 
                onClose={() => doSetOpen(false)}
                open={props.open === undefined ? open : props.open}
                >
                    <Box sx={style}>
                    <Stack>
                        <h4>{props.text}</h4>
                        <Box>
                            {props.children}
                        </Box>
                    </Stack>
                    </Box>
            </Modal>  
        </span>  
    )
}

export default ModalText;