import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Hideable from './Hideable';
import Validate from './Validate';
import SignInThirdParty from './SignInThirdParty';
import { Alert, Box, Button, Container, Stack, TextField } from '@mui/material';

const SIGN_IN_WITH_PASSWORD = gql`
mutation SignInWithPassword($email:String!, $password:String!) {
  signInWithPassword(email:$email, password:$password)
}`
;

const SignIn = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  document.title = `navscout - Sign In`;

  return <Container>
      <h3>Sign in with email</h3>
      <form fluid="true" onSubmit={e => {
          e.preventDefault();
          signInWithPassword(props.client, {email, password}, navigate, setError);
        }} >
          <Stack spacing={2}>
        <TextField name='email' label='Email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        <TextField type='password' label='Password' name='password' placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />
          <Box>
        <Button
              disabled={!Validate.notBlank(email, password)}
              variant='contained'
              onClick={e => {
                e.preventDefault();
                signInWithPassword(props.client, {email, password}, navigate, setError);
              }}
              >Sign In</Button>
        </Box>
        <Hideable hidden={!error}>
          <Alert severity='error'>{error}</Alert>
        </Hideable>      
        </Stack>    
      </form>
      <a href='/sign-in/get-token'>Recover your password</a>
      &nbsp; &bull; &nbsp;
      <a href='/sign-up'>Sign up</a>
      <SignInThirdParty account={props.account} client = {props.client} />
    </Container>;
}

const signInWithPassword = (client, values, navigate, setError) => {
  client.mutate({
    mutation: SIGN_IN_WITH_PASSWORD,
    variables: values,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.signInWithPassword === null) {
      setError('Sign In failed');
    } else {
      navigate('/');
    }
  }).catch(error => setError(error.message));
}


export default SignIn;