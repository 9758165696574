import React, { useState } from 'react';
import Hideable from './Hideable';
import { Alert, Box, Button, Modal, Stack, TextField } from '@mui/material';

const MIN_LENGTH = 8;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AccountPasswordUpdate = props => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState("");

  let message = null;

  if (password === null || password.length < MIN_LENGTH) {
    message = "Your password must be atleast 8 characters"
  } else if (password !== verify) {
    message = "Your password must match the verification"
  }


  const close = () => { 
    setOpen(false); 
    if (props.clearOnClose) {
      setPassword(""); 
      setVerify(""); 
    }
  }
  
  return (<div>
      <Modal
      open={open}
      onClose={() => close() }
      >
         <Box sx={style}>
        <Stack>
        <h4>Update Password</h4>
          <form onSubmit={e => {
              e.preventDefault();
              props.onSubmit({password});
              close();
            }}>
              <Stack>
              <label>New password</label>
              <TextField type="password" value={password} name="password" onChange={e => {e.preventDefault(); setPassword(e.target.value)}} />
              <label>Verify your password</label>
              <TextField type="password" value={verify} name="verify" onChange={e => {e.preventDefault(); setVerify(e.target.value)}} />
            <Button disabled={message !== null} onClick={() => {
              props.onSubmit({password});
              close();
            }}>
              {props.submit||'Submit'}
            </Button>
            <Hideable hidden={props.onDelete ? false : true}>
              <Button onClick={() => {props.onDelete();close();} }>Delete</Button>
            </Hideable>
            <br/>
            <Hideable hidden={message === null}>
              <Alert
                severity='warning'
                header='Password check failed'
                >Password check failed: {message}</Alert>
            </Hideable>
            </Stack>
          </form>
          </Stack>
          </Box>
      </Modal>
      <Button 
        style={{margin:'0 auto 0 auto'}}
        primary={true}
        onClick={() => setOpen(true)}
        >Update Password</Button>
    </div>)
}

export default AccountPasswordUpdate;