import React from 'react';
import './App.css';

const AboutTermsOfService = props => {
    return (
        <div>
            <h1>Terms of Service</h1>
            <Terms />
        </div>
    )
}


const Terms = () => {
    return (<pre>{`
Terms of Service for navscout.com

Effective Date: October 1, 2024

1. Introduction

Welcome to navscout.com. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using navscout.com, you agree to be bound by these Terms. If you do not agree to all the terms and conditions, please do not use the site.

2. Account Registration

You must be at least 18 years old to use navscout.com or have legal capacity as defined by your local laws.
You are responsible for maintaining the confidentiality of your account information and password.

3. User Conduct

You agree to use navscout.com only for lawful purposes.
You must not distribute, modify, transmit, reuse, repost, or use the content of navscout.com for public or commercial purposes without our explicit written permission.

4. Intellectual Property

All content on navscout.com, unless otherwise noted, is our property or that of our partners and is protected by copyright, trademark, and other laws.
You may not reproduce, distribute, or create derivative works from our content without permission.

5. User Content

By submitting content to navscout.com, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any intellectual property and other rights associated with your content.
You represent and warrant that you own or have the necessary rights to your content.

6. Prohibited Activities

You may not engage in any activity that interferes with or disrupts navscout.com or the servers and networks connected to navscout.com.
You may not attempt to gain unauthorized access to navscout.com, other accounts, computer systems, or networks connected to navscout.com through hacking, password mining, or any other means.

7. Termination

We may terminate or suspend your access to navscout.com at any time, without notice, for any reason, including, without limitation, if you breach these Terms.

8. Disclaimers

navscout.com is provided "as-is" and without warranties of any kind, either express or implied.
We do not warrant that navscout.com will be uninterrupted, timely, secure, or error-free.

9. Limitation of Liability

In no event shall navscout.com or its suppliers be liable for any indirect, punitive, incidental, special, or consequential damages arising out of or in connection with these Terms or the use of or inability to use navscout.com.

10. Indemnification

You agree to indemnify and hold navscout.com harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms or your use of navscout.com.

11. Governing Law

These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of laws principles.

12. Changes to These Terms

We reserve the right to modify or replace these Terms at any time. Your continued use of navscout.com after any changes to these Terms constitutes your acceptance of such changes.

13. Severability

If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms.

14. Entire Agreement

These Terms, together with our Privacy Policy, constitute the entire agreement between you and navscout.com regarding your use of the website and services.

15. Contact Information

For any questions or concerns regarding these Terms, you may contact us at support@navscout.com (mailto:support@navscout.com).

By using navscout.com, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
    `}</pre>)
}
export default AboutTermsOfService;