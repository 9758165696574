import gql from 'graphql-tag';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Hideable from './Hideable';
import SignInGoogle from './SignInGoogle';
import Util from './Util';


const signInWithToken  = (client, values, navigate, setError) => {
  client.mutate({
    mutation: SIGN_IN_WITH_TOKEN,
    variables: values,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.signInWithToken === null) {
      setError?.('Sign In failed');
    } else {
      navigate('/');
    }
  }).catch(error => setError?.(error.message));
}

const SIGN_IN_WITH_TOKEN = gql`
mutation SignInWithToken($provider:String!, $token:String!) {
  signInWithToken(provider:$provider, token:$token)
}`
;

const SignInThirdParty = props => {
    let navigate = useNavigate();
    return (<Hideable hidden={Util.isDefined(props.account)}>
      <h3>Sign in with Google</h3>
      <SignInGoogle
        disabled={Util.isDefined(props.account)} 
        onSuccess={(provider, token) => {
          signInWithToken(props.client, {provider, token}, navigate, props.setError)
        }}
        />
  </Hideable>)
}

export default SignInThirdParty;