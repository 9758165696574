import React from 'react';
import './App.css';
import AdContent from './AdContent';
import CardScroll from './CardScroll';
import { Container } from '@mui/material';

const Images = props => {
    return (
        <Container>
            <h1>Gallery</h1>
            <CardScroll client={props.client} />
            <AdContent id='ad' />
        </Container>
    )
}  

export default Images;