import gql from 'graphql-tag';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Util from './Util';
import { Autocomplete, Box, IconButton, Stack, TextField } from '@mui/material';
import debounce from 'lodash/debounce'; // You'll need to install lodash
import { GpsFixed } from '@mui/icons-material';

const FIND_PLACE = gql`
query FindPlace($label: String!) {
  place(criteria: {label:$label}) {
    places {
      id
      label
      location { label locality adminDistrict countryCode }
    }
  }
  autocomplete(input:$label) {
    label
  }
}
`;

const GEOLOCATE = gql`
query {
  geolocate {
    id
    label
  }
}
`;

const place = label => {
  return { label, type: 'PLACE' }
}

const IGNORE_TRAIL_CHARACTERS = ' \t,'

/**
 * 
 * @param {*} props
 * @param {string} props.location The text location name, ie "Boston, MA"
 * @returns 
 */
const LocationInput = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(props.location || "");
  const [loading, setLoading] = useState(false);

  /*
  The selected place: {
    id: place id (optional, present if source is navscout)
    label: place label
    source: navscout | google
  }
  */
  const [selectedValue, setSelectedValue] = useState({label: props.location || ""})

  const fetchOptions = debounce(async (query) => {
    if (!open) {
      return
    }
    if (!query) {
      setOptions([]);
      return;
    }

    setLoading(true);
    try {
      search(props.client, query, setLoading, setOptions, () => { })
    } catch (error) {
      console.error('Error fetching data:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  }, 300); // 300ms debounce delay

  // Trigger fetch when input value changes
  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  return (
    <Box sx={{minWidth: "30em"}}>
      <Stack sx={{minWidth: "30em"}} direction="row">
      <Autocomplete
        sx={{width:"100%"}}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        getOptionLabel={(option) => option.label}
        loading={loading}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          props.onChange?.({ label: newInputValue })
        }}
        value={selectedValue} // Controlled value
        onChange={(event, newValue) => {
          setSelectedValue(newValue); // Update selected value when user selects an option
        }}
        filterOptions={(x) => x} // Disable client-side filtering since backend handles it
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder || 'Location'}
            variant="outlined"
          />
        )}
      />
      <IconButton onClick={e => {
        e.preventDefault()
        geolocate(props.client, () => { }, l => {
          console.log(l.label);
          setInputValue(l.label)
          props.onChange?.(l)
          console.log(l.label)
          setSelectedValue(l)
        })
      }
      }>
        <GpsFixed />
      </IconButton>
      </Stack>
    </Box>
  )
}

/**
 * 
 * @param {*} client client to make call
 * @param {*} cb callback, passes the place data
 */
const geolocate = (client, onError, cb) => {
  if (!client) {
    onError({ message: "Client not provided" })
  }
  client.query({
    query: GEOLOCATE
  }).then(data => {
    if (data.data.geolocate === null || data.data.geolocate.label === null) {
      onError({ message: 'Unable to determine location' });
    } else {
      cb(data.data.geolocate);
    }
  })
}

const search = (client, v, setLoading, setResults, onError) => {
  if (!Util.isDefined(v) || v === "") {
    setResults([])
    setLoading(false)
    return
  }

  setLoading(true);

  client.query({
    query: FIND_PLACE,
    variables: { label: v }
  }).then(data => {
    var results = [];
    var places = data?.data?.place?.places;
    var location = data?.data?.autocomplete;

    if (places) {
      places.map(p => {
        return {
          label: p.label,
          title: p.label,
          id: p.id,
          value: `${p.location.locality}, ${p.location.adminDistrict} ${p.location.countryCode}`,
          source: 'navscout',
          last: 0
        }
      }).forEach(p => results.push(p));
    }

    if (location) {
      location.map(p => {
        return {
          label: p.label,
          title: p.label,
          value: p.label,
          source: 'google',
          last: 0
        }
      }).forEach(p => {
        //do not add a duplicate place
        if (results.filter(r => r.label === p.label).length > 0) {
          return
        }
        results.push(p)
      })
    }

    if (results && results.length > 0) results[results.length - 1].last = 1;

    setResults(results);
    setLoading(false);
  }).catch(err => {
    onError(err);
  })
}


export default LocationInput;